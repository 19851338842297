import React from "react"
import Layout from "../../layouts/MainLayout"

export default function Execom() {
  return (
    <Layout>
      <div className="banner">All Execoms</div>
    </Layout>
  )
}
